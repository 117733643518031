exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._157Ix {\n\tpadding: 15px;\n\ttext-align: center;\n\tfont-size: 16px;\n    margin-bottom: 18px;\n    margin-top: 18px;\n\tborder-radius: 8px;\n\tcursor: pointer;\n}\n._22Js_ {\n\tdisplay: inline-block;\n\tpadding: 0px 8px;\n\twidth: 20%;\n}\n._157Ix:hover,\n._157Ix:focus,\n._157Ix:active {\n\tbackground-color: rgb(242, 242, 242);\n\tcolor: #484848;\n}\n.T9nDk:first-child {\n\tbackground-color: #fff;\n\tcolor: #484848;\n\tborder: 1px solid #767676;\n}\n@media screen and (max-width: 767px) {\n\t._22Js_ {\n\t\twidth: 50%;\n\t}\n}", ""]);

// exports
exports.locals = {
	"activeItem": "_157Ix",
	"mainSection": "_22Js_",
	"activeSection": "T9nDk"
};