exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n:root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Segoe UI', 'HelveticaNeue-Light', sans-serif;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1000px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;  /* Extra small screen / phone */\n  --screen-sm-min: 768px;  /* Small screen / tablet */\n  --screen-md-min: 992px;  /* Medium screen / desktop */\n  --screen-lg-min: 1200px; /* Large screen / wide desktop */\n\n  --btn-primary-bg: #000;\n  --btn-boxShadow-bg: #000;\n  --btn-bordercolor-bg: #000;\n  --btn-text-color: #fff;\n  --btn-primary-color: #ffffff;\n  --common-color: #000;\n  --btn-secondary-bg: #000;\n\n}\n\n._1QFug {\n  background: #f5f5f5;\n  color: #333;\n}\n\n._1aTkE {\n  margin: 0 auto;\n  padding: 20px 8px;\n  max-width: 1000px;\n  max-width: var(--max-content-width);\n  text-align: center;\n  font-size: 1.5em; /* ~24px */\n}\n\n._2iH7A,\n._2iH7A:active,\n._2iH7A:hover,\n._2iH7A:visited {\n  color: #333;\n  text-decoration: none;\n}\n\n._2iH7A:hover {\n  text-decoration: underline;\n}\n\n._3GZrp {\n  padding-right: 15px;\n  padding-left: 15px;\n}\n", ""]);

// exports
exports.locals = {
	"root": "_1QFug",
	"container": "_1aTkE",
	"link": "_2iH7A",
	"spacer": "_3GZrp"
};